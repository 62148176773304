<template>
  <div id="maps" class="column is-7 is-hidden-touch">
    <l-map ref="map" :zoom="9" :center="center" :options="{ zoomControl: false }">
      <l-tile-layer
        ref="tileLayer"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="<a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
      />
      <l-marker
        v-for="(marker, index) in mapMarkers"
        :key="`${marker.title}${index}`"
        :lat-lng="marker.position"
        :options="marker.options"
        @click="highlight(marker.id)"
      >
        <l-tooltip :options="tooltipOptions">
          {{ marker.title }}
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { latLng, point } from 'leaflet';

export default {
  props: {
    markers: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tooltipOptions: {
        direction: 'top',
        offset: point([-15, -10]),
      },
    };
  },
  computed: {
    ...mapState({
      center: (state) => state.map,
    }),
    mapMarkers() {
      return this.markers
        .filter((marker) => {
          const position = marker.locations?.[0]?.position;
          return position && position?.lat && position?.lng;
        })
        .map((marker) => ({
          id: marker.objectID,
          position: latLng(marker.locations[0].position.lat, marker.locations[0].position.lng),
          title: marker.name,
          options: {
            id: marker.objectID,
          },
        }));
    },
  },
  methods: {
    highlight(id) {
      const allResults = document.querySelectorAll('.ais-InfiniteHits a.media');
      allResults.forEach(
        (result) => result.classList.remove('has-background-secondary-lighter'),
      );

      const selected = document.getElementById(id);
      if (selected) {
        selected.classList.add('has-background-secondary-lighter');
        selected.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
  watch: {
    mapMarkers(markers) {
      const bounds = markers.map((marker) => marker.position);
      this.$refs.map.mapObject.fitBounds(bounds);
    },
  },
};
</script>
