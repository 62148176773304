<template>
  <div
    id="search-results"
    :class="{ grid: currentDirectory.view === 'grid' }"
    class="px-5"
  >
    <ActiveRefinements />
    <ais-configure :hits-per-page.camel="250" />

    <ais-infinite-hits
      class="columns is-multiline has-search-results is-justify-content-center"
      :transform-items="transform"
    >
      <template v-slot="{ items, refineNext, isLastPage }">
        <template v-for="(item, index) in items">
          <AddRecord
            v-if="index === 0"
            :key="`${index}addRecord`"
            :class="{
              'column is-4 has-record': currentDirectory.view === 'grid',
              'column is-12 has-record media': currentDirectory.view !== 'grid',
            }"
          />

          <Ad :key="`${index}ad`" ad-slot="ResultsAd" :index="index" />

          <router-link
            :id="item.objectID"
            :key="`${index}record`"
            :to="{ name: 'record', params: { record: item.slug }}"
            :class="{
              'column has-record': true,
              'is-featured': item.sponsor,
              'media is-12': currentDirectory.view !== 'grid',
              'is-4': currentDirectory.view === 'grid',
            }"
            :aria-label="item.name"
            @mouseleave.native.self="$emit('dehighlight', item.objectID)"
            @mouseover.native="$emit('highlight', item.objectID)"
          >
            <div class="container">
              <div class="featured-icons">
                <FeaturedBadge :sponsored="item.sponsor" />
                <ExpertBadge :expert="item.expert" />
              </div>
              <RecordImage
                v-if="item.sponsor"
                :image-uri="item['image-uri']"
                :name="item.name"
              />
              <div class="media-content">
                <h4 class="title is-size-4 is-marginless">
                  <span
                    v-if="isFilteredView && item.rank !== 9999"
                    :class="{
                      'subtitle is-rank': currentDirectory.view !== 'grid',
                    }"
                    v-html="item.rank"
                  />
                  <span>{{ item.name }}</span>
                  <RecordPrimaryFilterLabel
                    class="is-size-7 is-primary-filter-label"
                    :class="{ 'pl-5': isFilteredView && item.rank !== 9999 }"
                    :record="item"
                  />
                </h4>
                <RecordDetailSnapshot :sponsored="item.sponsor" :record="item" />
              </div>
            </div>
          </router-link>
        </template>
        <button
          class="loadResults"
          :disabled="isLastPage"
          @click="refineNext"
        >
          Show more results
        </button>
      </template>
    </ais-infinite-hits>
    <NoResults />
  </div>
</template>

<script>
/* eslint-disable */
import arrays from '@/mixins/arrays';
import {mapState, mapGetters} from 'vuex';
import {createInfiniteHitsSessionStorageCache} from 'instantsearch.js/es/lib/infiniteHitsCache';
import componentMap from '@/mixins/componentMap';
import AddRecord from '@/components/SearchResults/AddRecord.vue';

export default {
  mixins: [arrays, componentMap],
  data: () => ({
    cache: createInfiniteHitsSessionStorageCache(),
  }),
  computed: {
    ...mapState({
      results_ads: (state) => state.advertisements.results_ads,
      ad_provider: (state) => state.advertisements.provider,
      hasBanner: (state) => state.hasBanner,
      routeQuery: (state) => state.route.query,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
      primaryFilter: 'directory/primaryFilterField',
    }),
    isFilteredView() {
      return Object.keys(this.routeQuery?.[this.routeType] ?? {})
        ?.includes(this.primaryFilter?.key);
    },
    itemClass() {
      if (this.currentDirectory.view === 'grid') {
        return 'column is-4';
      }
      return 'column is-12';
    },
    routeType() {
      return this.routeMap?.[this.primaryFilter?.type];
    },
  },
  methods: {
    getRank(record, currentFilter) {
      // Gets only the FIRST key element for the field.
      // For example, `lists.key` is the primary filter, but we only want to get `lists`
      // as the field.
      const recordField = record[this.primaryFilter?.key?.split('.')[0]];
      let validRecord;

      // Change `list` to `key` when records in Algolia are updated
      if (Array.isArray(recordField)) {
        validRecord = recordField?.find((field) => field?.key === currentFilter);
      }

      // Return a "rank" of super high so it shows at the bottom of the list temporarily
      // before it's hidden by the facet filter again.
      return validRecord?.rank ?? 9999;
    },
    transform(items) {
      const filter = this.routeQuery
        ?.[this.routeType]
        ?.[this.currentDirectory.primary_filter.field];


      // Handle boolean data types that could be strings
      items = items.map((item) => ({
        ...item,
        sponsor: String(item.sponsor).toLowerCase() === 'true',
      }));

      const splitItems = this.groupBy(items, 'sponsor');
      if (splitItems[true]) {
        items = [...this.shuffle(splitItems[true]), ...splitItems[false] ?? []];
      }

      return items
          .map((item) => ({ ...item, rank: this.getRank(item, filter) }))
          .sort((a, b) => a.rank - b.rank);
    },
  },
  components: {
    ActiveRefinements: () => import('@/components/InstantSearch/ActiveRefinements.vue'),
    Ad: () => import('@/components/Ads/Ad.vue'),
    FeaturedBadge: () => import('@/components/Badges/Featured.vue'),
    ExpertBadge: () => import('@/components/Badges/Expert.vue'),
    RecordImage: () => import('@/components/SearchResults/RecordImage.vue'),
    RecordDetailSnapshot: () => import('@/components/SearchResults/RecordDetailSnapshot.vue'),
    RecordPrimaryFilterLabel: () => import('@/components/SearchResults/RecordPrimaryFilterLabel.vue'),
    NoResults: () => import('@/components/InstantSearch/NoResults.vue'),
    AddRecord,
  },
};
</script>
