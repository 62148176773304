export default {
  methods: {
    /* Shuffles an array using the Fisher-Yates algorithm */
    shuffle(array) {
      // eslint-disable-next-line no-plusplus
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // eslint-disable-next-line no-param-reassign
        [array[i], array[j]] = [array[j], array[i]];
      }

      return array;
    },
    /* groups an array of objects based on a property value */
    groupBy(array, property) {
      return array.reduce((accumulator, item) => {
        const value = item[property];

        if (!accumulator[value]) {
          // eslint-disable-next-line no-param-reassign
          accumulator[value] = [];
        }
        accumulator[value].push(item);

        return accumulator;
      }, {});
    },
  },
};
