export default {
  data: () => ({
    componentMap: {
      string: 'ais-menu',
      array: 'ais-refinement-list',
    },
    routeMap: {
      string: 'menu',
      array: 'refinement-list',
    },
  }),
};
