var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5",class:{ grid: _vm.currentDirectory.view === 'grid' },attrs:{"id":"search-results"}},[_c('ActiveRefinements'),_c('ais-configure',{attrs:{"hitsPerPage":250}}),_c('ais-infinite-hits',{staticClass:"columns is-multiline has-search-results is-justify-content-center",attrs:{"transform-items":_vm.transform},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refineNext = ref.refineNext;
var isLastPage = ref.isLastPage;
return [_vm._l((items),function(item,index){return [(index === 0)?_c('AddRecord',{key:(index + "addRecord"),class:{
            'column is-4 has-record': _vm.currentDirectory.view === 'grid',
            'column is-12 has-record media': _vm.currentDirectory.view !== 'grid',
          }}):_vm._e(),_c('Ad',{key:(index + "ad"),attrs:{"ad-slot":"ResultsAd","index":index}}),_c('router-link',{key:(index + "record"),class:{
            'column has-record': true,
            'is-featured': item.sponsor,
            'media is-12': _vm.currentDirectory.view !== 'grid',
            'is-4': _vm.currentDirectory.view === 'grid',
          },attrs:{"id":item.objectID,"to":{ name: 'record', params: { record: item.slug }},"aria-label":item.name},nativeOn:{"mouseleave":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('dehighlight', item.objectID)},"mouseover":function($event){return _vm.$emit('highlight', item.objectID)}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"featured-icons"},[_c('FeaturedBadge',{attrs:{"sponsored":item.sponsor}}),_c('ExpertBadge',{attrs:{"expert":item.expert}})],1),(item.sponsor)?_c('RecordImage',{attrs:{"image-uri":item['image-uri'],"name":item.name}}):_vm._e(),_c('div',{staticClass:"media-content"},[_c('h4',{staticClass:"title is-size-4 is-marginless"},[(_vm.isFilteredView && item.rank !== 9999)?_c('span',{class:{
                    'subtitle is-rank': _vm.currentDirectory.view !== 'grid',
                  },domProps:{"innerHTML":_vm._s(item.rank)}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))]),_c('RecordPrimaryFilterLabel',{staticClass:"is-size-7 is-primary-filter-label",class:{ 'pl-5': _vm.isFilteredView && item.rank !== 9999 },attrs:{"record":item}})],1),_c('RecordDetailSnapshot',{attrs:{"sponsored":item.sponsor,"record":item}})],1)],1)])]}),_c('button',{staticClass:"loadResults",attrs:{"disabled":isLastPage},on:{"click":refineNext}},[_vm._v(" Show more results ")])]}}])}),_c('NoResults')],1)}
var staticRenderFns = []

export { render, staticRenderFns }