<template>
  <div v-if="htmlWidget" class="htmlWidget" v-html="content" />
</template>

<script>
export default {
  props: {
    htmlWidget: {
      type: String,
    },
  },
  data: () => ({
    content: '',
  }),
  methods: {
    updateWidget() {
      fetch(`https://dj4.datajoe.com/widget/insights-html.php?${this.htmlWidget}`)
        .then((response) => response.text())
        .then((html) => {
          if (html.includes('UNAUTHORIZED ACCESS')) {
            return;
          }

          // TODO: set the vuex parameter to recalculate the offset height of the chart

          this.content = html;
        });
    },
  },
  watch: {
    htmlWidget() {
      this.content = '';
      this.updateWidget();
    },
  },
  mounted() {
    this.updateWidget();
  },
};
</script>
